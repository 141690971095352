import React from "react"
import { Container } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"
import JkfImageGallery from "../components/sections/jkf-gallery-section"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/products">
      <SEO
        title="Låger"
        description="Når du skal vælge dine nye låge- og skuffefronter, er der 2 ting du skal vælge. Det ene er farven og det andet er profilen/udskæringen af lågen. Hvad enten du er til det klassiskeMari landkøkken med fræsninger eller den stilrene og blanke overflade, kan Jysk Køkkenfornyelse  levere netop den kombination, som du ønsker. Herunder kan du […]"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="stort udvalg af låger"
        description="Låger i høj kvalitet, produceret i danmark"
      />
      <Container fluid>
        <JkfImageGallery images={data}></JkfImageGallery>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    files: allFile(filter: { relativeDirectory: { eq: "laager" } }) {
      edges {
        node {
          id
          childImageSharp {
            id
            fluid(maxHeight: 300, maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
